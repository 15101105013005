<template>
    <div>
        <div class="add-form">
            <el-form ref="form" :model="form" label-width="160px">
                <el-form-item label="添加标签">
                    <el-input
                        style="width: 270px"
                        v-model="form.tagName"
                        placeholder="请输入标签名称"
                        size="small"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { addLabel } from '@/api/user/label';
export default {
    props: ['category'],
    data() {
        return {
            form: {
                tagName: '',
            },
        };
    },
    methods: {
        onSubmit() {
            if (this.form.tagName == '') {
                this.$message.error('请输入标签名称');
            } else {
                let data = {
                    param: this.form,
                };
                data.param.type = this.$choiceLabel.state.type;
                data.param.category = this.category;
                addLabel(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('添加成功');
                    }
                    this.form.tagName = '';
                    this.$emit('close');
                });
            }
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add-form {
    padding: 32px 0 200px 0;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
