import { render, staticRenderFns } from "./dealings.vue?vue&type=template&id=68c6ed04&scoped=true&"
import script from "./dealings.vue?vue&type=script&lang=js&"
export * from "./dealings.vue?vue&type=script&lang=js&"
import style0 from "./dealings.vue?vue&type=style&index=0&id=68c6ed04&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68c6ed04",
  null
  
)

export default component.exports